import StatsManager from "@/lib/ConcorsandoWasm/StatsManager";
import {CONSTANTS, SOTTO_ARGOMENTI_MAX_DEPTH} from "@/lib/ConcorsandoWasm/Constants";
import PercorsiWrappedWorker from "@/lib/ConcorsandoWasm/PercorsiWrappedWorker";
import _ from 'lodash';
import * as DBOperations from "@/lib/ConcorsandoWasm/DBOperations";
import {getCumulativeSum, includesSottoPercorso, sanitizeSottoPercorsoId} from "@/lib/utils";
import * as utils from "@/lib/utils";

export default class PercorsiStatsManager extends  StatsManager {
  static get instance() {
    if (!this.percorsiStatsManagerInstance) {
      // Vedi nota a static get instance di PercorsiWrappedWorker
      this.percorsiStatsManagerInstance = new PercorsiStatsManager(CONSTANTS.PERCORSI_STATISTICHE_STORE, CONSTANTS.PERCORSI_MATERIA_KEY, CONSTANTS.PERCORSI_META, PercorsiWrappedWorker.instance);
    }
    return this.percorsiStatsManagerInstance;
  }

  /* OVERRIDES */

  makeMatchMateria(id_materie) {
    let materie = Array.isArray(id_materie) ? id_materie : [];
    materie = materie.map(x => sanitizeSottoPercorsoId(x));

    function matchMateria(id_materia) {
      if (materie.length > 0) {
        return materie.some(id => includesSottoPercorso(id, id_materia));
      } else {
        return true;
      }
    }

    return matchMateria;
  }

  getSortedStats(stats) {
    return stats;
  }

  getStatsFromBE(id_concorso, initial_date) {
    return new Promise((resolve, reject) =>
      this.reportisticaApi.getRispostePercorso(
        {
          modalita: "ESEGUITA",
          idArgomento: id_concorso,
          ...initial_date
        },
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        })
    );
  }

  async sendStatsToBE(body, incrementa_contatore) {
    return this.customReportisticaApi.rispostePercorsoAsync(body, incrementa_contatore)
  }

  async cancellaReportistica(id_concorso, id_materia) {
    return new Promise((resolve, reject) => {
      this.backofficeApi.cancellaReportistica({
        idArgomento: id_concorso,
        idSottoPercorso: id_materia
      }, (err, data) => {
        if (err == null) {
          resolve(data);
        } else {
          reject(err);
        }
      })
    });
  }

  /**
   * Effettua rimozione in locale delle statistiche. Ritorna una
   * @param {String} id_concorso
   * @param {{id_materie: Array<String>}} query
   * @param {IDBTransaction} [transaction] (enhanced by idb) if no transaction is passed, one will be created.
   * @returns {Promise<void>}
   */
  async resetStats(id_concorso, query = {}, {transaction} = {}) {
    return await DBOperations.resetStats(this.storeName, id_concorso, query, {
      transaction,
      materiaKey: this.materiaKey,
      matchMateria: (arr, sotto_percorso) => {
        /* arr: l'array di id di sottopercorsi da cancellare
         * sotto_percorso: l'id di un sottopercorso
         *
         * Bisogna ritornare true se sotto_percorso è un sottopercorso di almeno uno degli elementi di arr.
         */
        return arr.some(id => includesSottoPercorso(id, sotto_percorso));
      }
    });
  }

  getCompletamentoByMateria(id_materie, by_materia, non_risposte, nonRisposteByMateria) {
    let res = {by_materia: {}};

    const cumulativeTot = getCumulativeSum(by_materia);
    const nonRisposteCum = getCumulativeSum(nonRisposteByMateria);

    console.debug("cumulativeTot")
    console.debug(cumulativeTot)

    console.debug("nonRisposteCum")
    console.debug(nonRisposteCum)

    id_materie.forEach(id_materia => {
      const tot = cumulativeTot[id_materia] || 0;
      if (_.isFinite(tot) && tot > 0) {
        const risposte = tot - (nonRisposteCum[id_materia] || 0)
        res.by_materia[id_materia] = utils.percentage(risposte, tot);
      }
      else {
        res.by_materia[id_materia] = 0;
      }
    });
    return res;
  }

  getIdMaterie(by_materia) {
    return utils.ensureAllLevels(Object.keys(by_materia));
  }
}
