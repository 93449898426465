<template>
    <div id="paths">

        <!--## Blocco tabella miei ultimi concorsi ##-->
        <div class="card">
            <div class="card-body">
                <div class="float-right mb-2 ml-2">
                    <button @click="$router.push({ name: routesEnum.ADD_PATH })" class="btn btn-primary btn-sm btn-rounded"><i class="mdi mdi-file-document-box-plus-outline"></i> {{$t('paths.aggiungiNuovaMateria')}}</button>
                </div>
                <h5 class="header-title mb-4 text-uppercase"><i class="mdi mdi-school-outline font-size-24 mr-1 align-middle"></i> {{$t('paths.leMieUltimeMaterie')}}</h5>

                <template v-if="userPaths.length > 0">
                    <div class="table-responsive">
                        <b-table :tbody-tr-class="isPremium" head-variant="light"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :fields="fields"
                                 :items="userPaths"
                                 @row-clicked="setSelectedPathDetail">
                            <!-- Head table -->
                                <template v-slot:head(argomento)="data">
                                    <div>{{$t('paths.argomento')}}</div>
                                </template>
                                <template v-slot:head(stato)="data">
                                    <div class="text-center">{{$t('paths.stato')}}</div>
                                </template>
                                <template v-slot:head(domande)="data">
                                    <div class="text-center">{{$t('paths.domande')}}</div>
                                </template>
                                <template v-slot:head(azioni)="data">
                                    <div class="text-center">{{$t('paths.azioni')}}</div>
                                </template>
                            <!-- /Head table -->

                            <template v-slot:cell(argomento)="data">
                                <span :class="[((data.item.premium && profile.livello >= 2) ? 'text-white' : ''), 'font-weight-semibold']">{{data.value}}</span>
                                <span v-if="data.item.premium && profile.livello >= 2" class="ml-3 badge badge-danger">Premium</span>
                            </template>

                            <template v-slot:cell(stato)="data">
                                <div class="text-center">
                                    <div v-if="verifyIfQuestionsAreDownloaded(data.item.id_argomento) > 0" class="btn btn-primary btn-rounded waves-effect waves-light">{{getCompletion(data.item.id_argomento)}}%</div>
                                    <div v-else :class="[((data.item.premium && profile.livello >= 2) ? 'text-white' : '')]">-</div>
                                </div>
                            </template>

                            <template v-slot:cell(domande)="data">
                                <div class="text-center">
                                    <span style="color: #3051d3;" v-if="verifyIfQuestionsAreDownloaded(data.item.id_argomento) > 0">{{verifyIfQuestionsAreDownloaded(data.item.id_argomento)}}</span>
                                    <img src="../assets/images/icon-cloud.png" style="width: 39px; height: 39px;" v-else>
                                </div>
                            </template>

                            <template v-slot:cell(azioni)="data">
                                <div class="text-center">
                                    <div class="btn-group" role="group">
                                        <button type="button" @click="showModalConfirm(data.item.id_argomento)" :class="[((data.item.premium && profile.livello >= 2) ? 'text-premium-hover bg-hover-premium' : ''), 'btn btn-outline-danger']" data-toggle="tooltip" data-placement="top" :title="$t('paths.eliminaMateria')">
                                            <i class="mdi mdi-trash-can"></i>
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="mt-4">
                        <div class="pagination pagination-rounded justify-content-center mb-0">
                            <b-pagination v-model="currentPage" :per-page="perPage" pills :total-rows="userPaths.length"></b-pagination>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">{{$t('paths.nonHaiAggiuntoMaterie')}}</div>
                </template>

                <!-- Modal delete path -->
                <div id="modalDelete" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalDeleteLabel">{{$t('paths.modale.delete.titolo')}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="modal-body-delete" class="modal-body">
                                <h5 class="font-size-16">{{$t('paths.modale.delete.sottotitolo')}}</h5>
                                <p>{{$t('paths.modale.delete.doveVuoiCancellareLaMateria')}}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deletePath('local')" data-dismiss="modal">{{$t('paths.modale.delete.locale')}}</button>
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deletePath('remote')" data-dismiss="modal">{{$t('paths.modale.delete.remoto')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal delete path -->

                <!-- Modal confirm -->
                <div id="modalConfirm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="myModalLabel">{{$t('paths.modale.confirm.titolo')}}</h5>
                            </div>
                            <div id="modal-body" class="modal-body">
                                <h5 class="font-size-16">{{$t('paths.modale.confirm.sottotitolo')}}</h5>
                                <p>{{$t('paths.modale.confirm.seiSicuroDiVolerCancellareLaMateria')}}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.chiudi')}}</button>
                                <button type="button" class="btn btn-primary waves-effect waves-light" @click="deletePath('remote')" data-dismiss="modal">{{$t('generale.ok')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal confirm -->

                <!-- Modal paths error -->
                <div id="modalPathsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPathsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalPathsErrorLabel">{{$t('paths.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-paths-error" class="modal-body">
                                <p id="message-body-paths-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal paths error -->

                <!-- Modal paths request error -->
                <div id="modalPathsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPathsRequestsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalPathsRequestsErrorLabel">{{$t('paths.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-paths-requests-error" class="modal-body">
                                <p id="message-body-paths-requests-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesEnum.HOME })">{{$t('generale.annulla')}}</button>
                                <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getPercorsiUtente()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal paths request error -->

                <!-- Modal message premium -->
                <div id="modalMessagePremium" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessagePremiumLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalMessagePremiumLabel">{{$t('paths.modale.messagePremium.titolo')}}</h5>
                            </div>
                            <div id="modal-body-message-premium" class="modal-body">
                                {{ $t('paths.modale.messagePremium.perUtilizzareMateriaPremium') }}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                                <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesEnum.SUBSCRIPTION })">{{$t('paths.modale.messagePremium.passaPremium')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal message premium -->

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

    </div>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import {mapActions, mapGetters} from 'vuex'
    import {MODULES} from "@/store/modules-enum";
    import {BPagination, BTable} from 'bootstrap-vue'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import PercorsiWrappedWorker from "../lib/ConcorsandoWasm/PercorsiWrappedWorker";
    import Swal from "sweetalert2";
    import _ from 'lodash'
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import PercorsiStatsManager from "@/lib/ConcorsandoWasm/PercorsiStatsManager";
    import {PATH_ACTIONS} from "@/store/path/actions-enum";
    import {PATH_MUTATIONS} from "@/store/path/mutations-enum";
    import {routesEnums} from "@/helpers/enums";
    import {AUTH_GETTERS} from "@/store/auth/getters-enum";
    import {getNumberBetween} from "@/lib/utils";

    const percorsiWorker = PercorsiWrappedWorker.instance
    const percorsiStatsManager = PercorsiStatsManager.instance

    export default {
        name: "Paths",
        components: {
            BTable,
            BPagination
        },
        data () {
            return {
                routesEnum: routesEnums,
                arrayOfIdPathCompletion: [],
                perPage: 10,
                currentPage: 1,
                fields: [
                    {
                        key: 'argomento',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Stato',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Domande',
                        tdClass: 'verticalCenter'
                    },
                    {
                        key: 'Azioni',
                        tdClass: 'verticalCenter'
                    }
                ],
                metaPaths: [],
                idSelectedPath: {}
            }
        },
        computed: {
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.USER_PATHS, PATH_GETTERS.SELECTED_PATHS])
        },
        mounted() {
            this.backToTop()
            this.getPercorsiUtente()
        },
        methods: {
            ...mapActions(MODULES.PATH, [PATH_ACTIONS.DELETE_USER_PATH, PATH_ACTIONS.GET_USER_PATHS, PATH_ACTIONS.SET_SELECTED_PATH]),
            getCompletion (idPath) {
                const path = _.find(this.arrayOfIdPathCompletion, (c) => { return c.idConcorso === idPath && c.tot !== undefined })
                return (path !== undefined) ? getNumberBetween(path.tot, 0, 100) : 0
            },
            async setCompletion () {
                this.arrayOfIdPathCompletion = await Promise.all(this.userPaths
                    .map(userPath =>
                        percorsiStatsManager.getCompletamento(userPath.id_argomento)
                            .then((result) => ({ idConcorso: userPath.id_argomento, tot: result.tot }))
                            .catch(() => {})
                    )
                )
            },
            deleteSelectedPath (idSelectedPath) {
                const selectedPaths = _.cloneDeep(this.selectedPaths)
                this.selectedPaths.forEach((e, i) => {
                    if (e.id_argomento === idSelectedPath) {
                        selectedPaths.splice(i, 1)
                    }
                })
                this.$store.commit(MODULES.PATH + "/" + PATH_MUTATIONS.SET_SELECTED_PATH, selectedPaths)
            },
            deletePath (type) {
                this.$emit('is-components-ready', 'paths', false)
                const context = this
                if (type === 'local') {
                    percorsiWorker.delConcorso(this.idSelectedPath)
                        .then(() => {
                            context.$emit('is-components-ready', 'paths', true)
                            context.deleteSelectedPath(context.idSelectedPath)
                            context.getAllMetaPaths()
                            context.showModalDeleteSweetAlert()
                        })
                        .catch(() => {
                            context.$emit('is-components-ready', 'paths', true)
                            $('#modalPathsError').modal('show')
                            $('#message-body-paths-error').html(context.$t('paths.modale.errore.cancellazioneMateria'))
                        })
                } else {
                    percorsiStatsManager.resetStatsRemote(this.idSelectedPath, undefined, {remote: true})
                        .catch((err) => {
                            console.error(err)
                        })
                        .finally(() => {
                            this.deleteUserPath(this.idSelectedPath)
                                .then(() => {
                                    context.deleteSelectedPath(context.idSelectedPath)
                                    context.getPercorsiUtente(true)
                                })
                                .catch(() => {
                                    context.$emit('is-components-ready', 'paths', true)
                                    $('#modalPathsError').modal('show')
                                    $('#message-body-paths-error').html(context.$t('paths.modale.errore.cancellazioneMateria'))
                                })
                        })
                }
            },
            getPercorsiUtente (isDeletePathRequest) {
                $('#modalPathsRequestsError').modal('hide')
                const context = this
                this.$emit('is-components-ready', 'paths', false)
                $('#button-retry-paths').attr('disabled', 'disabled')
                this.getUserPaths()
                    .then(() => {
                        this.getAllMetaPaths()
                        return this.setCompletion()
                    })
                    .then(() => {
                        $('#modalPathsError').modal('hide')
                        context.$emit('is-components-ready', 'paths', true)
                        if (isDeletePathRequest !== undefined && isDeletePathRequest) {
                            context.showModalDeleteSweetAlert()
                        }
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'paths', true)
                        $('#modalPathsRequestsError').data('bs.modal', null)
                        $('#modalPathsRequestsError').modal({backdrop: 'static', keyboard: false})
                        $('#button-retry-paths').removeAttr('disabled')
                        $('#message-body-paths-requests-error').html(context.$t('paths.modale.errore.materieUtente'))
                    })
            },
            showModalConfirm (idPath) {
                this.idSelectedPath = idPath
                if (this.verifyIfQuestionsAreDownloaded(idPath) > 0) {
                    $('#modalDelete').modal('show')
                } else {
                    $('#modalConfirm').modal('show')
                }
            },
            getAllMetaPaths () {
                const context = this
                percorsiWorker.getAllMeta()
                    .then((response) => {
                        context.metaPaths = response
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'paths', true)
                        $('#modalPathsError').modal({backdrop: 'static', keyboard: false})
                        $('#message-body-paths-error').html(context.$t('paths.modale.errore.getMetaMaterie'))
                    })
            },
            verifyIfQuestionsAreDownloaded (idPath) {
                let n_questions = 0
                this.metaPaths.forEach(competition => {
                    if (competition.id_argomento === idPath) {
                        n_questions = competition.n_domande
                    }
                })
                return n_questions
            },
            setSelectedPathDetail (row) {
                if (this.profile.livello >= 2 && row.premium) {
                    $('#modalMessagePremium').modal('show')
                } else {
                    let context = this
                    this.setSelectedPath(row.id_argomento)
                        .then(() => {
                            this.$router.push({ name: routesEnums.PATH_DETAIL_WRAPPER})
                        })
                        .catch(() => {
                            $('#modalPathsError').modal('show')
                            $('#message-body-paths-error').html(context.$t('paths.modale.errore.materiaNonTrovata'))
                        })
                }
            },
            showModalDeleteSweetAlert () {
                Swal.fire({
                    title: this.$t('paths.sweetAlert.cancellaMateria.title'),
                    text: this.$t('paths.sweetAlert.cancellaMateria.text'),
                    icon: 'success',
                    confirmButtonText: this.$t('generale.ok')
                })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            isPremium (item, type) {
                let classes = 'cursorPointer'
                if (type === 'row' && item.premium !== undefined && item.premium && this.profile.livello >= 2) {
                    classes += ' bg-premium'
                }
                return classes
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .text-hover-premium {
            color: #f06543;
        }

        .text-hover-premium:hover {
            color: white !important;
        }

        .bg-hover-premium {
            background-color: white;
        }

        .bg-hover-premium:hover {
            background-color: #f06543 !important;
        }
    }
</style>